/*
 * Copyright © 2024 Opera Norway AS. All rights reserved.
 *
 * This file is an original work developed by Opera.
 */

'use client'

import Cookies from 'js-cookie'
import { useRouter } from 'next/navigation'
import { useEffect } from 'react'

import { REDIRECT_URL_KEY } from '~/utils/consts'

export default function Page() {
  const router = useRouter()
  useEffect(() => {
    router.push(Cookies.get(REDIRECT_URL_KEY) ?? '/games/all')
  }, [router])
  return null
}
